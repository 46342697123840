// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-paneles-regiones-index-js": () => import("../src/templates/paneles-regiones/index.js" /* webpackChunkName: "component---src-templates-paneles-regiones-index-js" */),
  "component---src-templates-solar-panels-index-js": () => import("../src/templates/solar-panels/index.js" /* webpackChunkName: "component---src-templates-solar-panels-index-js" */),
  "component---src-templates-products-js": () => import("../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-categories-js": () => import("../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-resource-js": () => import("../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-solution-js": () => import("../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogo-domestico-js": () => import("../src/pages/catalogo/domestico.js" /* webpackChunkName: "component---src-pages-catalogo-domestico-js" */),
  "component---src-pages-catalogo-index-js": () => import("../src/pages/catalogo/index.js" /* webpackChunkName: "component---src-pages-catalogo-index-js" */),
  "component---src-pages-catalogo-industria-js": () => import("../src/pages/catalogo/industria.js" /* webpackChunkName: "component---src-pages-catalogo-industria-js" */),
  "component---src-pages-catalogo-portatil-js": () => import("../src/pages/catalogo/portatil.js" /* webpackChunkName: "component---src-pages-catalogo-portatil-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-index-js": () => import("../src/pages/industrial/index.js" /* webpackChunkName: "component---src-pages-industrial-index-js" */),
  "component---src-pages-mapa-solar-js": () => import("../src/pages/mapa-solar.js" /* webpackChunkName: "component---src-pages-mapa-solar-js" */),
  "component---src-pages-paneles-solares-js": () => import("../src/pages/paneles-solares.js" /* webpackChunkName: "component---src-pages-paneles-solares-js" */),
  "component---src-pages-particular-index-js": () => import("../src/pages/particular/index.js" /* webpackChunkName: "component---src-pages-particular-index-js" */),
  "component---src-pages-politicas-de-garantia-js": () => import("../src/pages/politicas-de-garantia.js" /* webpackChunkName: "component---src-pages-politicas-de-garantia-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-producto-js": () => import("../src/pages/producto.js" /* webpackChunkName: "component---src-pages-producto-js" */),
  "component---src-pages-quienes-somos-js": () => import("../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-recursos-js": () => import("../src/pages/recursos.js" /* webpackChunkName: "component---src-pages-recursos-js" */),
  "component---src-pages-rural-index-js": () => import("../src/pages/rural/index.js" /* webpackChunkName: "component---src-pages-rural-index-js" */),
  "component---src-pages-soluciones-index-js": () => import("../src/pages/soluciones/index.js" /* webpackChunkName: "component---src-pages-soluciones-index-js" */),
  "component---src-pages-utilitario-index-js": () => import("../src/pages/utilitario/index.js" /* webpackChunkName: "component---src-pages-utilitario-index-js" */)
}

